import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase/firebaseConfig';
import GAConnector from './GAConnector';
import GAAccountList from './GAAccountList';
import GAAddServiceAccount from './GAAddServiceAccount';
import GAPropertyList from './GAPropertyList';

const GASelector = () => {
  // State management
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [gaAccounts, setGaAccounts] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const [showAllAccounts, setShowAllAccounts] = useState(true);
  const [gaAccountId, setGAAccountId] = useState(null);
  const [serviceAccountStatus, setServiceAccountStatus] = useState(true);
  const [propertyListStatus, setPropertyListStatus] = useState(true);

  useEffect(() => {
    // Get user email from localStorage
    const email = auth.currentUser.email;
    if (email) {
      setUserEmail(email);
    }

    // Load the Google Identity Services library
    const loadGIS = async () => {
      if (!window.google || !window.google.accounts) {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      }
    };

    loadGIS();
  }, []);

  const handleAccountsChange = accounts => {
    setGaAccounts(accounts);
    setShowAllAccounts(true);
    setAuthenticated(true);
  };

  if (!authenticated) {
    return (
      <GAConnector
        onAccountsChange={handleAccountsChange}
        onAccessTokenChange={token => setAccessToken(token)}
      />
    );
  }
  return (
    <div>
      <div className="mt-6 bg-white p-6 rounded-lg shadow">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">
            {showAllAccounts
              ? 'Your Google Analytics Accounts'
              : `Selected Google Analytics Account ${gaAccountId}`}
          </h3>
        </div>

        {showAllAccounts && (
          <>
            <GAAccountList
              gaAccounts={gaAccounts}
              onAccountSelect={account => setGAAccountId(account)}
              onShowAccountSelector={selectorState => setShowAllAccounts(selectorState)}
            />
          </>
        )}
      </div>
      {serviceAccountStatus && !showAllAccounts && (
        <>
          <GAAddServiceAccount
            token={accessToken}
            account={gaAccountId}
            onStatusChange={setServiceAccountStatus}
          />
        </>
      )}

      {propertyListStatus && !serviceAccountStatus && (
        <>
          <GAPropertyList account={gaAccountId} onStatusChange={setPropertyListStatus} />
        </>
      )}
    </div>
  );
};

export default GASelector;
