import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const GAConnector = ({ onAccountsChange, onAccessTokenChange }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [accessToken, setAccessToken] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [accountsError, setAccountsError] = useState(null);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [gaAccounts, setGaAccounts] = useState([]);

  useEffect(() => {
    if (onAccountsChange && gaAccounts.length > 0) {
      onAccountsChange(gaAccounts);
    }
  }, [gaAccounts, onAccountsChange]);

  // New useEffect to pass accessToken to parent when it changes
  useEffect(() => {
    if (onAccessTokenChange && accessToken) {
      console.log('token from GAConn', accessToken);
      onAccessTokenChange(accessToken);
    }
  }, [accessToken, onAccessTokenChange]);

  const handleGoogleAuth = () => {
    setLoading(true);
    setError(null);

    // Make sure the Google Identity Services library is loaded
    if (!window.google || !window.google.accounts) {
      setError('Google authentication not available. Please refresh the page and try again.');
      setLoading(false);
      return;
    }

    // Initialize the token client
    const tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: process.env.REACT_APP_GCP_webApp_clientId,
      scope:
        'https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.manage.users',
      callback: async response => {
        if (response && response.access_token) {
          console.log('Authentication successful, received token');

          // Store the access token
          setAccessToken(response.access_token);

          // Store authentication status in Firestore
          if (userEmail) {
            try {
              const userRef = doc(db, 'users', userEmail);
              await setDoc(
                userRef,
                {
                  activeGAAccount: {
                    authenticated: true,
                    lastUpdated: new Date().toISOString(),
                  },
                },
                { merge: true }
              );
            } catch (err) {
              console.error('Error updating Firestore:', err);
            }
          }

          setAuthenticated(true);
          setLoading(false);

          // Fetch accounts using the token
          fetchAccounts(response.access_token);
        } else {
          setError('Failed to authenticate with Google Analytics');
          setLoading(false);
        }
      },
      error_callback: err => {
        console.error('Authentication error:', err);
        setError('Failed to authenticate: ' + (err.message || 'Unknown error'));
        setLoading(false);
      },
    });

    // Request a token
    tokenClient.requestAccessToken({ prompt: 'consent' });
  };

  const fetchAccounts = async token => {
    try {
      setAccountsLoading(true);
      setAccountsError(null);

      console.log('Fetching accounts with token', token.substring(0, 10) + '...');
      console.log(`Using backend API URL: ${API_URL}`);

      // Make request to our backend API
      const response = await axios.post(`${API_URL}/ga/accounts`, {
        access_token: token,
      });

      console.log('Accounts response:', response.data);

      // Process the response based on which API returned data
      if (response.data.accounts) {
        // Admin API v1 response format
        setGaAccounts(response.data.accounts);
        // Directly pass to parent as well for immediate update
        if (onAccountsChange) {
          onAccountsChange(response.data.accounts);
        }

        console.log(`Found ${response.data.accounts.length} accounts (Admin API)`);
      } else if (response.data.items) {
        // Management API v3 response format
        setGaAccounts(response.data.items);
        // Directly pass to parent as well for immediate update
        if (onAccountsChange) {
          onAccountsChange(response.data.items);
        }
        console.log(`Found ${response.data.items.length} accounts (Management API)`);
      } else {
        // No accounts found
        setGaAccounts([]);
        // Pass empty array to parent
        if (onAccountsChange) {
          onAccountsChange([]);
        }
        console.log('No accounts found in response');
      }
    } catch (err) {
      console.error('Error fetching accounts from backend:', err);
      setAccountsError(
        err.response?.data?.error ||
          err.response?.data?.message ||
          'Failed to fetch accounts from the server'
      );
    } finally {
      setAccountsLoading(false);
    }
  };

  return (
    <div className="mt-6 bg-white p-6 rounded-lg shadow">
      <h3 className="text-lg font-medium mb-4">Connect to Google Analytics</h3>
      <p className="text-gray-600 mb-6">
        Connect your Google Analytics account to access analytics data for your websites and
        applications.
      </p>
      <button
        onClick={handleGoogleAuth}
        className="inline-flex items-center px-4 py-2 border border-solid text-sm font-medium rounded-md shadow-sm text-black bg-white-600 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <svg viewBox="0 0 24 24" className="h-5 w-5 mr-2" aria-hidden="true">
          <path
            d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
            fill="#EA4335"
          />
          <path
            d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
            fill="#4285F4"
          />
          <path
            d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
            fill="#FBBC05"
          />
          <path
            d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
            fill="#34A853"
          />
        </svg>
        Connect Google Analytics
      </button>
    </div>
  );
};
export default GAConnector;
