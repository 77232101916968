import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase/firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const TopPages = ({ propertyId }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reportData, setReportData] = useState([]); // Initialize as empty array instead of undefined

  useEffect(() => {
    if (propertyId) {
      console.log('propertyId:', propertyId);
      pageData();
    } else {
      setLoading(false);
    }
  }, [propertyId]);

  const pageData = async () => {
    try {
      console.log('Page data fetching...');
      setLoading(true);
      console.log('Full URL:', `${API_URL}/ga/top_pages`);
      const response = await axios.post(`${API_URL}/ga/top_pages`, {
        gaProperty: propertyId,
      });

      // Make sure we're setting an array
      if (Array.isArray(response.data)) {
        setReportData(response.data);
      } else if (response.data && typeof response.data === 'object') {
        // If it's not an array but an object with data
        setReportData(Array.isArray(response.data.data) ? response.data.data : []);
      } else {
        // Fallback to empty array
        setReportData([]);
      }

      console.log('Data:', response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching page data:', error);
      setError('Failed to fetch page data. Please try again later.');
      setLoading(false);
    }
  };

  // Show loading state
  if (loading) {
    return <div className="text-center py-4">Loading page data...</div>;
  }

  // Show error state
  if (error) {
    return <div className="text-red-600 py-4">{error}</div>;
  }

  // Show empty state
  if (!reportData || reportData.length === 0) {
    return <div className="text-gray-500 py-4">No page data available.</div>;
  }

  return (
    <div className="mt-4">
      <p className="font-medium mb-3">Property ID: {propertyId}</p>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {reportData.map((item, index) => (
          <div key={index} className="border rounded-lg p-4 bg-white shadow-sm">
            <h3 className="font-medium text-blue-600 truncate" title={item.pagePath}>
              {item.pagePath}
            </h3>
            <div className="flex justify-between mt-2">
              <span className="text-sm text-gray-500">
                {item.month}/{item.year} (Week {item.week})
              </span>
              <span className="font-bold text-green-600">{item.pageViews} views</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopPages;
