import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase/firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const SERVICE_ACCOUNT_EMAIL = process.env.REACT_APP_SERVICE_ACCOUNT_EMAIL;

const GAAddServiceAccount = ({ token, account, onStatusChange }) => {
  const [addUserError, setAddUserError] = useState(null);
  const [addUserSuccess, setAddUserSuccess] = useState(null);
  const [addingUser, setAddingUser] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [gaAccountId, setGAAccountId] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [componentStatus, setComponentStatus] = useState(true);
  // const [accountId, setAccountId] = useState("");

  // Effect for passing componentStatus back to parent
  useEffect(() => {
    if (onStatusChange) {
      onStatusChange(componentStatus);
    }
  }, [componentStatus, onStatusChange]);

  // Separate effect for setting accessToken
  useEffect(() => {
    if (token) {
      setAccessToken(token);
    }
  }, [token]);

  // Separate effect for setting accessToken
  useEffect(() => {
    if (account) {
      setGAAccountId(account);
    }
  }, [account]);

  const addUserToAccount = async () => {
    console.log('passed account', account, token);
    setGAAccountId(account);
    setAccessToken(token);

    if (!accessToken) {
      setAddUserError('No access token available');
      return;
    }

    try {
      setAddingUser(true);
      setAddUserError(null);
      setAddUserSuccess(null);

      console.log(`Adding user ${SERVICE_ACCOUNT_EMAIL} to account ID: ${gaAccountId}`);

      // Make request to our backend API
      const response = await axios.post(`${API_URL}/ga/add_user`, {
        access_token: accessToken,
        account_id: gaAccountId,
        user_email: SERVICE_ACCOUNT_EMAIL,
      });

      console.log('Add user response:', response.data);

      if (response.data.success) {
        setComponentStatus(false);
        setAddUserSuccess(response.data.message);

        // Save the GA account details to Firestore on successful addition
        if (userEmail && selectedAccount) {
          try {
            const userRef = doc(db, 'users', userEmail);
            // Determine account ID and name
            let accountId, accountName;

            if (selectedAccount.name && selectedAccount.name.includes('accounts/')) {
              accountId = selectedAccount.name;
              accountName = selectedAccount.displayName || selectedAccount.name;
            } else if (selectedAccount.id) {
              accountId = selectedAccount.id;
              accountName = selectedAccount.displayName || selectedAccount.name;
            } else if (selectedAccount.accountId) {
              accountId = selectedAccount.accountId;
              accountName = selectedAccount.displayName || selectedAccount.name;
            }

            await setDoc(
              userRef,
              {
                activeGAAccount: {
                  accountId: accountId,
                  accountName: accountName,
                  serviceAccountAdded: true,
                  lastUpdated: new Date().toISOString(),
                },
              },
              { merge: true }
            );

            console.log('Updated Firestore with GA account details', componentStatus);
          } catch (err) {
            console.error('Error updating Firestore:', err);
          }
        }
      } else {
        setAddUserError('Unexpected response format');
      }
    } catch (err) {
      console.error('Error adding user to account:', err);
      setAddUserError(
        err.response?.data?.error ||
          err.response?.data?.message ||
          'Failed to add user to Google Analytics account'
      );
    } finally {
      setAddingUser(false);
      setComponentStatus(false);
    }
  };

  return (
    <div className="mt-6 bg-white p-6 rounded-lg shadow">
      <h3 className="text-lg font-medium mb-2">Add Service Account as Viewer</h3>
      <p className="text-sm text-gray-600 mb-4">
        Add the Firebase Admin service account as a Viewer to this account:
      </p>

      <div className="mb-4 p-3 bg-gray-50 rounded-md text-sm">
        <code className="text-gray-700 break-all">{SERVICE_ACCOUNT_EMAIL}</code>
      </div>

      {addUserSuccess && (
        <div className="mb-4 p-3 bg-green-50 border border-green-200 rounded-md">
          <p className="text-sm text-green-700">{addUserSuccess}</p>
        </div>
      )}

      {addUserError && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md">
          <p className="text-sm text-red-700">{addUserError}</p>
        </div>
      )}

      <button
        onClick={addUserToAccount}
        disabled={addingUser}
        className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
          addingUser ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
        }`}
      >
        {addingUser ? (
          <>
            <svg
              className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Adding User...
          </>
        ) : (
          'Add as Viewer'
        )}
      </button>
    </div>
  );
};

export default GAAddServiceAccount;
