import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase/firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';

const GAAccountList = ({ gaAccounts, onAccountSelect, onShowAccountSelector }) => {
  const [addUserError, setAddUserError] = useState(null);
  const [addUserSuccess, setAddUserSuccess] = useState(null);
  const [propertiesError, setPropertiesError] = useState(null);
  const [showAllAccounts, setShowAllAccounts] = useState(true);
  const [gaProperties, setGaProperties] = useState([]);
  const [gaAccountId, setGAAccountId] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [accountsError, setAccountsError] = useState(null);
  // const [gaAccounts, setGaAccounts] = useState([]);

  useEffect(() => {}, [gaAccounts]);

  const handleAccountSelect = account => {
    // Extract account ID based on API format
    let accountId;

    if (account.name && account.name.includes('accounts/')) {
      // Admin API format: "accounts/123456789"
      accountId = account.name;
    } else if (account.id) {
      // Management API format with 'id' field
      accountId = account.id;
    } else if (account.accountId) {
      // Management API alternate format
      accountId = account.accountId;
    } else {
      setPropertiesError('Could not determine account ID from the selected account');
      return;
    }

    setGAAccountId(accountId);
    setSelectedAccount(account);
    setGaProperties([]);
    setAddUserSuccess(null);
    setAddUserError(null);

    // Hide other accounts
    setShowAllAccounts(false);

    // Save to Firestore
    if (auth.currentUser.email) {
      try {
        const userRef = doc(db, 'users', auth.currentUser.email);
        const accountName = account.displayName || account.name;
        setDoc(
          userRef,
          {
            activeGAAccount: {
              accountId: accountId,
              accountName: accountName,
              property: null,
              lastUpdated: new Date().toISOString(),
            },
          },
          { merge: true }
        );
      } catch (err) {
        console.error('Error updating Firestore:', err);
      }
    }

    // Pass the selected account to the parent component
    if (onAccountSelect) {
      onAccountSelect(accountId);
      onShowAccountSelector(false);
    }
  };

  // Authenticated but loading accounts
  if (accountsLoading) {
    return (
      <div className="mt-6 bg-white p-6 rounded-lg shadow">
        <div className="flex justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
        <p className="text-center text-gray-500 mt-4">Loading your Google Analytics accounts...</p>
      </div>
    );
  }

  // Error fetching accounts
  if (accountsError) {
    return (
      <div className="mt-6 bg-white p-6 rounded-lg shadow border-l-4 border-orange-500">
        <h3 className="text-lg font-medium text-orange-800">Connected to Google Analytics</h3>
        <p className="mt-2 text-gray-600">
          Your account has been successfully connected to Google Analytics.
        </p>
        <div className="mt-4 p-4 bg-orange-50 rounded-md">
          <p className="text-sm text-orange-700">{accountsError}</p>
        </div>
        <div className="mt-4">
          <a
            href="https://analytics.google.com/analytics/web/"
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 inline-block"
          >
            Open Google Analytics
          </a>
        </div>
      </div>
    );
  }

  // Successfully fetched accounts but none found
  if (gaAccounts.length === 0) {
    return (
      <div className="mt-6 bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium">Google Analytics Accounts</h3>
        <p className="mt-2 text-gray-600">
          No Google Analytics accounts found. Make sure your Google account has access to Google
          Analytics.
        </p>
        <a
          href="https://analytics.google.com/analytics/web/"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-800"
        >
          Create a Google Analytics account
          <svg className="ml-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </a>
      </div>
    );
  }

  return (
    <>
      <p className="text-sm text-gray-600 mb-4">Select an account to view its properties:</p>
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <tbody className="divide-y divide-gray-200 bg-white">
            {gaAccounts.map(account => {
              // Determine the account ID for display
              let displayId;
              if (account.name && account.name.includes('accounts/')) {
                displayId = account.name.split('/').pop();
              } else if (account.id) {
                displayId = account.id;
              } else if (account.accountId) {
                displayId = account.accountId;
              } else {
                displayId = 'Unknown';
              }

              return (
                <tr key={displayId} onClick={() => handleAccountSelect(account)}>
                  {/* <button onClick={() => handleAccountSelect(account)}> */}
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {account.displayName || account.name} ({displayId})
                  </td>

                  {/* </button> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GAAccountList;
