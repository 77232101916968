import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase/firebaseConfig';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import saLogo from '../assets/img/SA Logo.png';
import GASelector from './admin/GASelector';
import TopPages from './reporting/TopPages';

const Home = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState('');
  const [activeGAAccountId, setActiveGAAccountId] = useState('');
  const [activeGAAccountName, setActiveGAAccountName] = useState('');
  const [gaStatus, setGAStatus] = useState(false);
  const [gaAccountData, setGaAccountData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);

  useEffect(() => {
    let unsubscribe = null;

    const setupRealtimeUpdates = async () => {
      try {
        setLoading(true);

        // Get current user
        const currentUser = auth.currentUser;
        if (!currentUser) {
          setError('No authenticated user found');
          setLoading(false);
          return;
        }

        setUserEmail(currentUser.email);

        // Get user reference
        const userRef = doc(db, 'users', currentUser.email);

        // Initial data fetch
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();

          if (userData.activeGAAccount) {
            const gaData = userData.activeGAAccount;
            updateGAData(gaData);
          } else {
            console.log('No active GA account found for this user');
          }
        } else {
          console.log('No user document found in Firestore');
        }

        // Set up real-time listener
        unsubscribe = onSnapshot(
          userRef,
          doc => {
            if (doc.exists()) {
              const userData = doc.data();
              if (userData.activeGAAccount) {
                console.log('Real-time update received for GA account data');
                updateGAData(userData.activeGAAccount);
              }
            }
          },
          error => {
            console.error('Error listening to Firestore updates:', error);
          }
        );
      } catch (err) {
        console.error('Error setting up data updates:', err);
        setError('Failed to load user data. Please refresh the page.');
      } finally {
        setLoading(false);
      }
    };

    // Helper function to update GA data in state and localStorage
    const updateGAData = gaData => {
      setGaAccountData(gaData);
      setActiveGAAccountId(gaData.accountId || '');
      setActiveGAAccountName(gaData.accountName || '');
      setGAStatus(gaData.serviceAccountAdded || false);
      setLastUpdate(new Date().toISOString());

      // Also update localStorage for backward compatibility
      localStorage.setItem('gaAccountName', gaData.accountName || '');
      localStorage.setItem('gaAccountId', gaData.accountId || '');
      localStorage.setItem('gaServiceAccountAdded', gaData.serviceAccountAdded || false);
    };

    setupRealtimeUpdates();

    // Clean up the listener when component unmounts
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const handleActiveGAToggle = () => {
    setGAStatus(!gaStatus);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Clear localStorage
      localStorage.removeItem('userEmail');
      localStorage.removeItem('gaAccountName');
      localStorage.removeItem('gaAccountId');
      localStorage.removeItem('gaServiceAccountAdded');
      // Redirect to homepage
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Function to format timestamp
  const formatDate = timestamp => {
    if (!timestamp) return 'N/A';

    try {
      const date = new Date(timestamp);
      return date.toLocaleString();
    } catch (error) {
      return 'Invalid date';
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header with logout */}
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <div className="flex items-center">
            <img src={saLogo} alt="Shift Analytics Logo" className="h-10 w-auto mr-4" />
            <h1 className="text-2xl font-bold tracking-tight text-gray-900">Dashboard</h1>
          </div>
          <div className="flex items-center gap-4">
            <p className="text-sm text-gray-600">{userEmail}</p>
            <button
              onClick={handleLogout}
              className="rounded-md bg-gray-50 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
            >
              Logout
            </button>
          </div>
        </div>
      </header>

      {/* Dashboard content */}
      <main className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
        {loading ? (
          <div className="bg-white p-8 rounded-lg shadow flex justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="bg-white p-8 rounded-lg shadow">
            <div className="p-4 bg-red-50 border border-red-200 rounded-md">
              <p className="text-red-700">{error}</p>
            </div>
          </div>
        ) : (
          <div className="bg-white p-8 rounded-lg shadow">
            {gaStatus ? (
              <div>
                <h2 className="text-lg font-medium mb-4">
                  Welcome to your analytics dashboard for{' '}
                  <a
                    href="#"
                    className="text-blue-600 hover:text-blue-800 underline cursor-pointer"
                    onClick={handleActiveGAToggle}
                  >
                    {activeGAAccountName} ({activeGAAccountId}){' '}
                  </a>
                </h2>
                <p className="text-gray-600 mb-6">Dashboard content here.</p>
                <div className="mt-8 border-t pt-6">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-medium">Your Google Analytics Account Data</h3>
                    {lastUpdate && (
                      <div className="flex items-center text-xs text-gray-500">
                        <svg
                          className="w-4 h-4 mr-1 text-green-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                          ></path>
                        </svg>
                        Updated: {formatDate(lastUpdate)}
                      </div>
                    )}
                  </div>
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                      <div>
                        <dt className="text-sm font-medium text-gray-500">Account Name</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {gaAccountData.accountName || 'Not set'}
                        </dd>
                      </div>
                      <div>
                        <dt className="text-sm font-medium text-gray-500">Account ID</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {gaAccountData.accountId || 'Not set'}
                        </dd>
                      </div>
                      <div>
                        <dt className="text-sm font-medium text-gray-500">Service Account Added</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {gaAccountData.serviceAccountAdded ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              Yes
                            </span>
                          ) : (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                              No
                            </span>
                          )}
                        </dd>
                      </div>
                      {gaAccountData.property && (
                        <>
                          <div>
                            <dt className="text-sm font-medium text-gray-500">Active Property</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {gaAccountData.property.displayName || gaAccountData.property}
                            </dd>
                          </div>
                          {gaAccountData.property.property && (
                            <div>
                              <dt className="text-sm font-medium text-gray-500">Property ID</dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {gaAccountData.property.property}
                              </dd>
                            </div>
                          )}
                        </>
                      )}
                    </dl>
                  </div>
                </div>
                {gaAccountData.property && (
                  <div className="mt-8">
                    <h3 className="text-lg font-medium">Analytics Data</h3>
                    {/* Display Firebase GA Account Data */}
                    {gaAccountData.property}
                    <TopPages propertyId={gaAccountData.property} />
                  </div>
                )}
              </div>
            ) : (
              <>
                <GASelector />

                {/* Display Firebase GA Account Data */}
              </>
            )}
          </div>
        )}
      </main>
    </div>
  );
};

export default Home;
