import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase/firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const GAPropertyList = ({ account, onStatusChange }) => {
  const [gaAccountId, setGAAccountId] = useState();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [allGAProperties, setAllGAProperties] = useState([]);
  const [targetGAProperties, setTargetGAProperties] = useState();
  const [componentStatus, setComponentStatus] = useState(true);
  // const [accountId, setAccountId] = useState("");

  // Effect for passing componentStatus back to parent
  useEffect(() => {
    if (onStatusChange) {
      onStatusChange(componentStatus);
    }
  }, [componentStatus, onStatusChange]);

  useEffect(() => {
    setGAAccountId(account);

    // Call fetchProperties function when component loads or account changes
    const fetchProperties = async () => {
      try {
        const response = await axios.post(`${API_URL}/ga/update_property`, {
          account_id: account,
        });

        if (Array.isArray(response.data.data)) {
          setAllGAProperties(response.data.data);
        } else {
          // Create a new array with just this single property
          setAllGAProperties([response.data.data]);
        }
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    fetchProperties();
  }, [account]);

  // Log gaAccountId when it changes
  useEffect(() => {
    console.log('GA Account passed for props:', gaAccountId);
  }, [gaAccountId]);

  // Handle property selection change
  const handlePropertyChange = property => {
    setSelectedProperty(property);
  };

  const storeActiveProperty = async () => {
    if (!selectedProperty) {
      console.error('No property selected');
      return;
    }

    try {
      const userRef = doc(db, 'users', auth.currentUser.email);

      // Find the selected property in allGAProperties
      const selectedPropertyData = allGAProperties.find(prop => prop.property === selectedProperty);

      if (!selectedPropertyData) {
        console.error('Selected property not found in properties list');
        return;
      }

      // Update Firestore with the selected property info
      await setDoc(
        userRef,
        {
          activeGAAccount: {
            property: selectedProperty,
            propertyName: selectedPropertyData.displayName,
            serviceAccountAdded: true,
            lastUpdated: new Date().toISOString(),
          },
        },
        { merge: true }
      );

      console.log(`Property ${selectedProperty} set as active`);
      setComponentStatus(!componentStatus);
    } catch (err) {
      console.error('Error updating Firestore:', err);
    }
  };

  return (
    <>
      <div className="mt-6 bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium mb-4">Select Google Analytics Property</h3>
        <p className="text-sm text-gray-600 mb-4">Choose a property to view analytics data:</p>

        <div className="mt-6 space-y-4">
          {allGAProperties.map(property => (
            <div key={property.property} className="flex items-center">
              <input
                id={property.property}
                name="analytics-property"
                type="radio"
                value={property.property}
                checked={selectedProperty === property.property}
                onChange={() => handlePropertyChange(property.property)}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor={property.property}
                className="ml-3 block text-sm font-medium text-gray-900"
              >
                {property.accountName}: {property.displayName} ({property.property})
              </label>
            </div>
          ))}
        </div>

        {allGAProperties.length > 0 ? (
          <div className="mt-6">
            <button
              onClick={storeActiveProperty}
              disabled={!selectedProperty}
              className={`px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                !selectedProperty
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700'
              }`}
            >
              Set Active Property
            </button>
          </div>
        ) : (
          <p className="mt-4 text-sm text-gray-500">No properties found for this account.</p>
        )}
      </div>
    </>
  );
};

export default GAPropertyList;
